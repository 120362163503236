import styled from 'styled-components';
import Head from 'next/head';
import { Loading } from 'components/Loading';
import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import {
  getLandingPage,
  landingPageKeys,
  useLandingPage,
} from 'queries/landing-page';
import { dehydrate, QueryClient } from 'react-query';
import buildImageUrl from 'cloudinary-build-url';
import { useEffect } from 'react';
import {
  Header,
  SubHeader,
  SectionLink,
} from '../components/LandingPage/landingPage.styles';
import Link from 'next/link';
import Image from 'next/image';
import HeaderComponent, { HeaderVariant } from 'shared/Header/Header';
import { Carousel } from 'components/LandingPage/Carousel';
import { Footer } from 'components/LandingPage/Footer';
import { motion } from 'framer-motion';
import Icon, { IconNames } from '../shared/Icons/Icon';
import { getUserAuth } from 'utils/server-auth';
import AccountPastDueAlert from 'components/AcccountPastDueAlert/AcccountPastDueAlert';

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  const queryClient = new QueryClient();
  await queryClient.prefetchQuery(landingPageKeys.all, getLandingPage);

  const data = getUserAuth(ctx);

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      data,
    },
  };
};

const LandingPage = ({
  data: { user },
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { data } = useLandingPage();

  useEffect(() => {
    document.body.style.backgroundColor = 'black';

    return () => {
      document.body.style.backgroundColor = 'white';
    };
  }, []);

  if (data) {
    return (
      <>
        <Head>
          <title>Solin - The Home of Social Fitness</title>
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          <meta
            property="og:title"
            content="Solin - The Home of Social Fitness"
            key="title"
          />
          <meta
            property="og:description"
            content="Solin is the home for private communities and fitness programs with your favorite creators. Talk, chat, hang out, share photos, stay close with your friends."
          />
          <meta
            property="og:image"
            content="https://www.solin.stream/images/ogImageLogo.png"
          />
          <meta
            name="description"
            key="description"
            content="Solin is the home for private communities and fitness programs with your favorite creators. Talk, chat, hang out, share photos, stay close with your friends."
          />
        </Head>
        <HeaderComponent variant={HeaderVariant.dark} user={user} />
        <Main>
          <AboveTheFold>
            <TopHeader>
              {data.sectionOneHeaderText.map((item, index) => (
                <span key={index} style={{ color: item.color }}>
                  {`${item.text} `}
                </span>
              ))}
            </TopHeader>
            <TopSubHeaderWrap>
              <TopSubHeader>{data.sectionOneSubHeader}</TopSubHeader>
              <Icon id={IconNames.arrowDown} color="var(--color-white)" />
            </TopSubHeaderWrap>
          </AboveTheFold>

          <Carousel cards={data.carouselOne} />

          <FloatingArrow
            animate={{
              y: [0, 10, 0, 4, 0, 2, 0],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              repeatDelay: 1,
            }}
          >
            <Icon id={IconNames.chevronDown} color="var(--color-white)" />
          </FloatingArrow>

          <CarouselTwo>
            <CarouselHeader>{data.sectionTwoHeader}</CarouselHeader>
            <Carousel cards={data.carouselTwo} />
          </CarouselTwo>

          <SectionWrap>
            <Header>{data.sectionThreeHeader}</Header>
            <SubHeader>{data.sectionThreeSubHeader}</SubHeader>
          </SectionWrap>
          <Logos>
            {data.sectionThreeImages.map((image) => (
              <ImageWrap key={image.id}>
                <Image
                  src={buildImageUrl(image.image, {
                    transformations: {
                      quality: 100,
                    },
                  })}
                  layout="fill"
                  objectFit="contain"
                />
              </ImageWrap>
            ))}
          </Logos>
          <LinkedSections>
            <SectionWrap>
              <Header>{data.sectionFourHeader}</Header>
              <SubHeader>{data.sectionFourSubHeader}</SubHeader>
              <Link href={data.sectionFourLink} passHref>
                <SectionLink>{data.sectionFourCTA}</SectionLink>
              </Link>
            </SectionWrap>
            <SectionWrap>
              <Header>{data.sectionFiveHeader}</Header>
              <SubHeader>{data.sectionFiveSubHeader}</SubHeader>
              <Link href={data.sectionFiveLink} passHref>
                <SectionLink>{data.sectionFiveCTA}</SectionLink>
              </Link>
            </SectionWrap>
          </LinkedSections>
        </Main>
        <Footer />

        <AccountPastDueAlert user={user} />
      </>
    );
  }

  return (
    <>
      <Head>
        <title>Solin - The Home of Social Fitness</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          property="og:title"
          content="Solin - The Home of Social Fitness"
          key="title"
        />
        <meta
          property="og:description"
          content="Solin is the home for private communities and fitness programs with your favorite creators. Talk, chat, hang out, share photos, stay close with your friends."
        />
        <meta
          property="og:image"
          content="https://www.solin.stream/images/ogImageLogo.png"
        />
        <meta
          name="description"
          key="description"
          content="Solin is the home for private communities and fitness programs with your favorite creators. Talk, chat, hang out, share photos, stay close with your friends."
        />
      </Head>
      <Loading />
    </>
  );
};

export default LandingPage;

const Main = styled.main`
  padding-bottom: 120px;
`;

const AboveTheFold = styled.div`
  padding: 0 64px;
  padding-top: 92px;
  padding-bottom: 48px;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding-left: 20px;
    padding-right: 28px;
    padding-top: 75px;
    padding-bottom: 32px;
  }
`;

const FloatingArrow = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 64px;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    display: none;
  }
`;

const TopHeader = styled.h1`
  font-family: 'HKGrotesk';
  font-size: 32px;
  font-weight: 600;
  line-height: 1.23;
  letter-spacing: 0.02em;
  padding-bottom: 8px;
  text-align: center;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    font-size: 42px;
    font-weight: 900;
    padding-bottom: 16px;
    text-align: revert;
  }
`;

const TopSubHeaderWrap = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    justify-content: flex-start;
  }
`;

const TopSubHeader = styled(SubHeader)`
  font-family: 'HKGrotesk';
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;
`;

const CarouselTwo = styled.div`
  padding-bottom: 52px;
`;

const CarouselHeader = styled.h3`
  font-family: 'HKGrotesk';
  font-size: 22px;
  font-weight: 500;
  line-height: 1.3;
  color: var(--color-white);
  padding-bottom: 32px;
  text-align: center;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding: 0 20px;
    padding-bottom: 24px;
    text-align: left;
  }
`;

const Logos = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 250px);
  justify-content: center;
  justify-items: center;
  row-gap: 48px;
  column-gap: 100px;
  padding-top: 48px;
  padding-bottom: 120px;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    grid-template-columns: repeat(2, 250px);
  }

  @media ${(p) => p.theme.queries.phoneAndDown} {
    grid-template-columns: repeat(2, 1fr);
    justify-content: stretch;
    row-gap: 16px;
    column-gap: 16px;
    padding-top: 16px;
    padding-bottom: 64px;
  }
`;

const ImageWrap = styled.div`
  position: relative;
  width: 250px;
  height: 167px;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    width: 150px;
    height: 100px;
  } ;
`;

const LinkedSections = styled.div`
  display: flex;
  justify-content: center;
  gap: 124px;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    flex-direction: column;
    gap: 64px;
  }
`;

const SectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 0 64px;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    align-items: flex-start;
    padding: 0 20px;
  }
`;
